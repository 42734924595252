import { FieldType } from './fieldTypes.js';

//date-select
//->
//user-sign-in
//user-create
//->
//create_hotel_booking

export const AVAILABLE_FORMS = {

  "meriton_listbay_v3": {
    //"formStyle": "profile",
    "className": "editform-listspot", // mainContent topPadded",
    //"className": "wpadmin-content top-padded-mobile",
    "pages":[
      {
        "id": "details",
        "title": `Tell us about your parking spot`,
        "title_new": `Tell us about your parking spot`,
        "fields":[
          {"id":"id.title", "label":"Space details","type":FieldType.TITLE},
          {"isRequired":true, "id":"bay_marking", label:"Bay Number #", type:FieldType.TEXT_STRING, placeholder:"Enter bay number"},

          {"isRequired":true, "id":"vehicle_type", label:"Maximum vehicle size", type:FieldType.SELECT_DROPDOWN, domain:'vehicle_types', placeholder:"How big is the spot?"},

          {"id":"id.title", "label":"How can drivers acccess this space?","type":FieldType.TITLE},
          {"isRequired":true, "id":"access_method", label:"Access type", type:FieldType.SELECT_DROPDOWN, domain:'access_methods', placeholder:"How big is the spot?"},

          
        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnClose",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next"
          }
        ]
      },
      {
        "id": "instructions",
        "title": `Describe your parking spot`,
        "title_new": `Describe your parking spot`,
        "fields":[
          {"id":"id.title", "label":"Describe your parking space","type":FieldType.TITLE},
          {"isRequired":true, "id":"description", label:"Description", type:FieldType.TEXTAREA, placeholder:"Describe what makes your spot special (eg. nearby locations, convenience)."},
          {"id":"id.title", "label":"Instructions for drivers","type":FieldType.TITLE},
          {"isRequired":false, "id":"instruction", label:"Access Instructions", type:FieldType.TEXTAREA, placeholder:"Describe how to access your spot. This will be hidden until a booking is made."}

        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnBack",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next"
          }
        ]
      },
      {
        "id": "pricing",
        "title": `Availabilities &amp; Price`,
        "title_new": `Availabilities &amp; Price`,
        "fields":[
          {"id":"id.title2", "label":`<p class="title">When is your car space available?</p>`,"type":FieldType.HTML},
          {"isRequired":true, "id":"availability_type", label:"Select an option", type:FieldType.SELECT_DROPDOWN, domain:'availability_types'},

          {"isRequired":true, "id":"auto_approve", label:"INSTANTLY ACCEPT BOOKING REQUESTS", type:FieldType.SLIDER},
          {"id":"id.title", "label":`<p>Accept Instant Bookings to increase your earnings and chances of your parking space being booked. If instant bookings are not allowed, you will need to accept each booking that comes through.</p>`,"type":FieldType.HTML},
          
          {"id":"id.title2", "label":`<p class="title" style="margin-bottom:0;">Set a monthly price</p>`,"type":FieldType.HTML},
          {"isRequired":true, "id":"rate_monthly", label:"Monthly Rate", type:FieldType.MONEY_ONLY_POSITIVE_DOLLARS, placeholder:'300'},
          {"id":"id.title3", "label":`<p>Please ensure you price is between X and X for the best chance of receiving a booking.</p>`,"type":FieldType.HTML},
          {"id":"id.title4", "label":`<p>* Your earnings will be transferred to you on a monthly basis, minus a <a href="https://www.sharewithoscar.com.au/{{window.WPDomain}}-faq/" target="_blank">service fee.</a></p>`,"type":FieldType.HTML},
          


        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnBack",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next"
          }
        ]
      },
      {
        "id": "results",
        "title": `Success!`,
        "title_new": `Success!`,
        "fields":[
          {id:"lblh",type:FieldType.ADVICE_BOX, label:`Your listing has been created and is available to rent.`},                    
        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnClose",
            "label":"Close"
          },
          {
            "id":"btnNext",
            "label":"Add Photos"
          }

        ]
      },
      {
        "id": "photos",
        "title": `Add photos of your spot`,
        "title_new": `Add photos of your spot`,
        "onSuccessUrl": "/listings",
        "fields":[
          //{"id":"id.title", "label":"Add photos of your spot","type":FieldType.TITLE},
          //{"id":"id.label", "label":`<p>Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.</p>`,"type":FieldType.HTML},
          {id:"lblh",type:FieldType.ADVICE_BOX, label:`Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.`},                    
          {"id":"id.spacer", "label":`<div style="height:50px;"></div>`,"type":FieldType.HTML},
          {"id":"id.photos.general", "label":`General photos of your spot`,"type":FieldType.IMAGEUPLOAD, imageType:"general"},
          {"id":"id.photos.street", "label":`Photos from the street`,"type":FieldType.IMAGEUPLOAD, imageType:"street"},
          {"id":"id.photos.access", "label":`Photos of access to the spot`,"type":FieldType.IMAGEUPLOAD, imageType:"access"},
        ],
        //"buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnSubmitPhotos",
            "label":"Save"
          }
        ]
      }
    ]

  },




  "community_listbay_v3": {
    //"formStyle": "profile",
    "className": "editform-listspot", // mainContent topPadded",
    //"className": "wpadmin-content top-padded-mobile",
    "pages":[
      {
        "id": "details",
        "title": `Tell us about your parking spot`,
        "title_new": `Tell us about your parking spot`,
        "fields":[
          {"id":"id.title", "label":"Space details","type":FieldType.TITLE},
          {"isRequired":true, "id":"bay_marking", label:"Bay Number #", type:FieldType.TEXT_STRING, placeholder:"Enter bay number"},

          {"isRequired":true, "id":"vehicle_type", label:"Maximum vehicle size", type:FieldType.SELECT_DROPDOWN, domain:'vehicle_types', placeholder:"How big is the spot?"},

          {"id":"id.title", "label":"How can drivers acccess this space?","type":FieldType.TITLE},
          {"isRequired":true, "id":"access_method", label:"Access type", type:FieldType.SELECT_DROPDOWN, domain:'access_methods', placeholder:"How big is the spot?"},

          
        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnClose",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next"
          }
        ]
      },
      {
        "id": "instructions",
        "title": `Describe your parking spot`,
        "title_new": `Describe your parking spot`,
        "fields":[
          {"id":"id.title", "label":"Describe your parking space","type":FieldType.TITLE},
          {"isRequired":true, "id":"description", label:"Description", type:FieldType.TEXTAREA, placeholder:"Describe what makes your spot special (eg. nearby locations, convenience)."},
          {"id":"id.title", "label":"Instructions for drivers","type":FieldType.TITLE},
          {"isRequired":false, "id":"instruction", label:"Access Instructions", type:FieldType.TEXTAREA, placeholder:"Describe how to access your spot. This will be hidden until a booking is made."}

        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnBack",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next"
          }
        ]
      },
      {
        "id": "pricing",
        "title": `Availabilities &amp; Price`,
        "title_new": `Availabilities &amp; Price`,
        "fields":[
          {"id":"id.title2", "label":`<p class="title">When is your car space available?</p>`,"type":FieldType.HTML},
          {"isRequired":true, "id":"availability_type", label:"Select an option", type:FieldType.SELECT_DROPDOWN, domain:'availability_types'},

          {"isRequired":true, "id":"auto_approve", label:"INSTANTLY ACCEPT BOOKING REQUESTS", type:FieldType.SLIDER},
          {"id":"id.title", "label":`<p>Accept Instant Bookings to increase your earnings and chances of your parking space being booked. If instant bookings are not allowed, you will need to accept each booking that comes through.</p>`,"type":FieldType.HTML},
          
          {"id":"id.title2", "label":`<p class="title" style="margin-bottom:0;">Set a monthly price</p>`,"type":FieldType.HTML},
          {"isRequired":true, "id":"rate_monthly", label:"Monthly Rate", type:FieldType.MONEY_ONLY_POSITIVE_DOLLARS, placeholder:'300'},
          {"id":"id.title3", "label":`<p>Please ensure you price is between X and X for the best chance of receiving a booking.</p>`,"type":FieldType.HTML},
          {"id":"id.title4", "label":`<p>* Your earnings will be transferred to you on a monthly basis, minus a <a href="https://www.sharewithoscar.com.au/{{window.WPDomain}}-faq/" target="_blank">service fee.</a></p>`,"type":FieldType.HTML},
          


        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnBack",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next"
          }
        ]
      },
      {
        "id": "results",
        "title": `Success!`,
        "title_new": `Success!`,
        "fields":[
          {id:"lblh",type:FieldType.ADVICE_BOX, label:`Your listing has been created and is available to rent.`},                    
        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnClose",
            "label":"Close"
          },
          {
            "id":"btnNext",
            "label":"Add Photos"
          }

        ]
      },
      {
        "id": "photos",
        "title": `Add photos of your spot`,
        "title_new": `Add photos of your spot`,
        "onSuccessUrl": "/listings",
        "fields":[
          //{"id":"id.title", "label":"Add photos of your spot","type":FieldType.TITLE},
          //{"id":"id.label", "label":`<p>Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.</p>`,"type":FieldType.HTML},
          {id:"lblh",type:FieldType.ADVICE_BOX, label:`Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.`},                    
          {"id":"id.spacer", "label":`<div style="height:50px;"></div>`,"type":FieldType.HTML},
          {"id":"id.photos.general", "label":`General photos of your spot`,"type":FieldType.IMAGEUPLOAD, imageType:"general"},
          {"id":"id.photos.street", "label":`Photos from the street`,"type":FieldType.IMAGEUPLOAD, imageType:"street"},
          {"id":"id.photos.access", "label":`Photos of access to the spot`,"type":FieldType.IMAGEUPLOAD, imageType:"access"},
        ],
        //"buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnSubmitPhotos",
            "label":"Save"
          }
        ]
      }
    ]

  },



  "oscar_listspace_v3": {
    //"formStyle": "profile",
    "className": "editform-listspot", // mainContent topPadded",
    //"className": "wpadmin-content top-padded-mobile",
    "pages":[
      {
          "id": "address",
          "title": `Edit Parking Space`,
          "title_new": `Add Parking Space`,
          "fields":[
            {"id":"id.title", "label":"Address","type":FieldType.TITLE},
            {"isRequired":true, "id":"address", "label":"Address","type":FieldType.ADDRESS_ONLY_AUSTRALIA,placeholder:"Enter your address"},
          ],
          "buttonsContainerStyle":"space-between",
          "buttons": [
            {
              "id":"btnClose",
              "label":"Back"
            },
            {
              "id":"btnSubmit",
              "label":"Next",
              "setId":"listspot_1"
            }
          ]
  
      },
      {
        "id": "details",
        "title": `Tell us about your parking spot`,
        "title_new": `Tell us about your parking spot`,
        "fields":[
          {"id":"id.title", "label":"Space details","type":FieldType.TITLE},

          {"isRequired":true, "id":"type", label:"Type of spot", type:FieldType.SELECT_DROPDOWN, domain:'oscar_space_types', placeholder:"What type of spot do you have?"},
          {"isRequired":true, "id":"vehicle_type", label:"Max. Vehicle Size", type:FieldType.SELECT_DROPDOWN, domain:'oscar_vehicle_types', placeholder:"How big is the spot?"},

          {"id":"id.title", "label":"How can drivers acccess this space?","type":FieldType.TITLE},
          {"isRequired":true, "id":"access_method", label:"Access type", type:FieldType.SELECT_DROPDOWN, domain:'oscar_access_methods', placeholder:"How big is the spot?"},

          {"id":"id.title", "label":"Any other details?","type":FieldType.TITLE},
          {"isRequired":true, "id":"electric_charge_type", label:"Electric charging", type:FieldType.SELECT_DROPDOWN, domain:'oscar_electric_types', placeholder:"How big is the spot?"},
          
        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnBack",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next",
            "setId":"listspot_2"
          }
        ]
      },
      {
        "id": "instructions",
        "title": `Describe your parking spot`,
        "title_new": `Describe your parking spot`,
        "fields":[
          {"id":"id.title", "label":"Describe your parking space","type":FieldType.TITLE},
          {"isRequired":true, "id":"description", label:"Description", type:FieldType.TEXTAREA, placeholder:"Describe what makes your spot special (eg. nearby locations, convenience)."},
          {"id":"id.title", "label":"Instructions for drivers","type":FieldType.TITLE},
          {"isRequired":false, "id":"instruction", label:"Access Instructions", type:FieldType.TEXTAREA, placeholder:"Describe how to access your spot. This will be hidden until a booking is made."}

        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnBack",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next",
            "setId":"listspot_3"
          }
        ]
      },
      {
        "id": "pricing",
        "title": `Set your price`,
        "title_new": `Set your price`,
        "fields":[
          {"id":"id.title", "label":`<p>Accept Instant Bookings to increase your earnings and chances of your parking space being booked. If instant bookings are not allowed, you will need to accept each booking that comes through.</p>`,"type":FieldType.HTML},
          {"isRequired":true, "id":"auto_approve", label:"INSTANTLY ACCEPT BOOKING REQUESTS", type:FieldType.SLIDER},
          
          {"id":"id.title", "label":`<p class="title" style="margin-bottom:0;">Casual Bookings</p>`,"type":FieldType.HTML, requires: [{fieldId: "auto_approve", value:true}]},
          {"isRequired":true, "id":"allow_casual", label:"ALLOW CASUAL BOOKINGS", type:FieldType.SLIDER, requires: [{fieldId: "auto_approve", value:true}]},
          {"isRequired":true, "id":"rate_hourly", label:"Hourly Rate", type:FieldType.MONEY_ONLY_POSITIVE_DOLLARS_AND_CENTS ,requires: [{fieldId: "auto_approve", value:true},{fieldId: "allow_casual", value:true}]},
          {"id":"id.noticeaccess", "label":`<p style="color:#ff0000;font-weight:500;">Please note, instant bookings for casual parking is not recommended for your parking space due to the limitations of accessing your space.</p>`,"type":FieldType.HTML, requires: [{fieldId: "allow_casual", value:true},{fieldId: "access_method", value:'SPECIAL_ACCESS_NOT_NONE'}]},
          {"id":"id.notice", "label":`<p>Casual bookings are only allowed if you enable Instant Bookings</p>`,"type":FieldType.HTML, requires: [{fieldId: "auto_approve", value:false}]},
          {"id":"id.title", "label":`<p class="title" style="margin-bottom:0;">Monthly Bookings</p>`,"type":FieldType.HTML},
          {"isRequired":true, "id":"allow_monthly", label:"ALLOW MONTHLY BOOKINGS", type:FieldType.SLIDER},
          {"isRequired":true, "id":"rate_monthly", label:"Monthly Rate", type:FieldType.MONEY_ONLY_POSITIVE_DOLLARS, requires: [{fieldId: "allow_monthly", value:true}]},
          


        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnBack",
            "label":"Back"
          },
          {
            "id":"btnSubmit",
            "label":"Next",
            "setId":"listspot_4"
          }
        ]
      },
      {
        "id": "results",
        "title": `Success!`,
        "title_new": `Success!`,
        "fields":[
          {id:"lblh",type:FieldType.ADVICE_BOX, label:`Your listing has been created. To start accepting bookings, add your spot's availabilities and photos.`},                    
        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnClose",
            "label":"Close"
          },
          {
            "id":"btnNext",
            "label":"Set Availability",
            "setId":"listspot_5"
          }

        ]
      },
      {
        "id": "availability",
        "title": `Set availability`,
        "title_new": `Set availability`,
        "fields":[
          {"id":"id.title", "label":"When is your space available to rent?","type":FieldType.TITLE},
          {"id":"is247", "label":"24/7","type":FieldType.SLIDER},
          {"id":"id.title2", "label":"Every week recurring","type":FieldType.TITLE, requires: [{fieldId: "is247", value:false}]},
          {"id":"selecteddays", "label":"Days of the week","type":FieldType.DAYSOFWEEK, requires: [{fieldId: "is247", value:false}]},
          {"id":"date_from", "label":"RANGE","type":FieldType.TIMERANGEPICKER, requires: [{fieldId: "is247", value:false}]},
          //{"id":"repeatweekly", "label":"Repeat weekly","type":FieldType.SLIDER, requires: [{fieldId: "is247", value:false}]},
          {"id":"id.noticemonthly", "label":`<p style="color:#ff0000;font-weight:500;">Your parking space needs to be available 24/7 to accept monthly bookings. Please go back and edit your availability on the pricing page.</p>`,"type":FieldType.HTML, requires: [{fieldId:'allow_monthly', value:true}, {fieldId: "is247", value:false}]},

        ],
        //"buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnSubmit",
            "label":"Next",
            "setId":"listspot_6"
          }
        ]
      },
      {
        "id": "availability2",
        "title": `Your spot is now live!`,
        "title_new": `Your spot is now live!`,
        "fields":[
          {id:"lblh",type:FieldType.ADVICE_BOX, label:`Your spot is now available on the platform to accept bookings. Double the chances of receiving a booking by adding a photo to your listing`},

        ],
        "buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnClose",
            "label":"Close"
          },
          {
            "id":"btnNext",
            "label":"Add Photos",
            "setId":"listspot_7"
          }
        ]
      },
      {
        "id": "photos",
        "title": `Add photos of your spot`,
        "title_new": `Add photos of your spot`,
        "onSuccessUrl": "/listings",
        "fields":[
          //{"id":"id.title", "label":"Add photos of your spot","type":FieldType.TITLE},
          //{"id":"id.label", "label":`<p>Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.</p>`,"type":FieldType.HTML},
          {id:"lblh",type:FieldType.ADVICE_BOX, label:`Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.`},                    
          {"id":"id.spacer", "label":`<div style="height:50px;"></div>`,"type":FieldType.HTML},
          {"id":"id.photos.general", "label":`General photos of your spot`,"type":FieldType.IMAGEUPLOAD, imageType:"general"},
          {"id":"id.photos.street", "label":`Photos from the street`,"type":FieldType.IMAGEUPLOAD, imageType:"street"},
          {"id":"id.photos.access", "label":`Photos of access to the spot`,"type":FieldType.IMAGEUPLOAD, imageType:"access"},
        ],
        //"buttonsContainerStyle":"space-between",
        "buttons": [
          {
            "id":"btnSubmitPhotos",
            "label":"Save",
            "setId":"listspot_8"
          }
        ]
      }
    ]

  },

  
  "create_hotel_booking": {
    "formStyle": "hotel",
    //"className": "editform-profile",
    "pages":[
        {
            "id": "start",
            "title": "When do you need parking?",
            "fields":[
              {"isRequired":false, "id":"access_code", "type":FieldType.DATEBOX, placeholder:"HOTEL here"},

            ],
            "buttons": [
              {
                "id":"btnNext",
                "label":"Next"
              }
            ]
    
        },
        {
          "id": "results",
          "title": "Search Results Page",
          "fields":[
            {id:"lblh",type:FieldType.HTML, label:
            `<p>Show parking space price here</p>`}                
          ],
          "buttons": [
            {
              "id":"btnNext",
              "label":"Next"
            }
          ]
  
      },
        {
          "id": "auth",
          "title": "AUTH PAGE",
          "fields":[
            {id:"lblh",type:FieldType.HTML, label:
            `<p>Sign In or Create Account process here</p>`
          }                
            

          ],
          "buttons": [
            {
              "id":"btnNext",
              "label":"Next"
            }
          ]
  
      },
      {
        "id": "payment",
        "title": "PAYMENT PAGE",
        "fields":[
          {id:"lblh",type:FieldType.HTML, label:
          `<p>Add Payment Details or use existing</p>`
        }                
          

        ],
        "buttons": [
          {
            "id":"btnNext",
            "label":"Next"
          }
        ]

    },

      {
          "id": "end",
          "title": "Booking Confirmation",
          "fields":[
            {id:"lblh",type:FieldType.HTML, label:
            `<p style="margin-top:50px;"><b>Note:</b> CONFIRMATION HERE</p>
            <p>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
            }                
        

          ],
          "buttons": [
            {
              "id":"btnClose",
              "label":"Close"
            }
          ]
  
      }

    ]
  },
  "public_oscar_monthly_end_booking": {
    "formStyle": "profile",
    "className": "editform-profile",
    "pages":[
        {
            "id": "start",
            "title": "End Your Booking",
            "fields":[
                {"id":"id.header", "label":"<p>Please select your end date</p>","type":FieldType.HTML},
                {"isRequired":true, "id":"cease_date", "label":"End Date","type":FieldType.DATE_ALL, minDate: "min_cease_date", maxDate: "max_cease_date"},
                {"isRequired":true, "id":"cease_reason", "label":"Reason for cancellation","type":FieldType.SELECT_DROPDOWN, domain:'cease_reason'},
                {id:"lblh",type:FieldType.HTML, label:
                `<p style="margin-top:50px;"><b>Note:</b> A minimum notice period of 1 month is required to end a booking.</p>
                <p>View the <a href='https://www.sharewithoscar.com.au/oscar-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                }                
            ],
            "headerButtons":[
              {
                "id":"btnClose"
              }
            ],
            "buttons": [
              {
                "id":"btnSubmit",
                "label":"Confirm End Date"
              }
            ]
        },
        {
            "id": "confirmation",
            "title": "End Date Confirmation",
            "fields":[
                {id:"lblh",type:FieldType.HTML, label:
                `<p>Your booking {{reference}} ends on <b>{{cease_date}}</b>.</p>
                <p>If you have been issued an access card or key, please make sure to return it on or before <b>{{cease_date}}</b> or
                you may be charged a penalty.</p>`
                },                                        
            ],
            "buttons": [
              {
                "id":"btnClose",
                "label":"View Booking"
              }
            ]
        },
    ]
  },
  "public_oscar_monthly_end_booking_owner": {
    "formStyle": "profile",
    "className": "editform-profile",
    "pages":[
        {
            "id": "start",
            "title": "End Your Booking",
            "fields":[
                {"id":"id.header", "label":"<p>Please select your end date</p>","type":FieldType.HTML},
                {"isRequired":true, "id":"cease_date", "label":"End Date","type":FieldType.DATE_ALL, minDate: "min_cease_date", maxDate: "max_cease_date"},
                {id:"lblh",type:FieldType.HTML, label:
                `<p style="margin-top:50px;"><b>Note:</b> A minimum notice period of 1 month is required to end a booking.</p>
                <p>View the <a href='https://www.sharewithoscar.com.au/oscar-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                }                
            ],
            "headerButtons":[
              {
                "id":"btnClose"
              }
            ],
            "buttons": [
              {
                "id":"btnSubmit",
                "label":"Confirm End Date"
              }
            ]
        },
        {
            "id": "confirmation",
            "title": "End Date Confirmation",
            "fields":[
                {id:"lblh",type:FieldType.HTML, label:
                `<p>The booking {{reference}} ends on <b>{{cease_date}}</b>.</p>
                `
                },                                        
            ],
            "buttons": [
              {
                "id":"btnClose",
                "label":"View Booking"
              }
            ]
        },
    ]
  },
  "public_oscar_monthly_cancel_booking": {
    "formStyle": "profile",
    "className": "editform-profile",
        "pages":[
            {
                "id": "start",
                "title": "Cancel Booking",
                "fields":[
                    {id:"lblh",type:FieldType.HTML, label:`You may cancel your booking on or before the commencement date of your rental. A credit for the full amount will automatically be applied to your account.<br/><br/>Credits can be used towards any other booking on the Share with Oscar platform. If you would like to request a refund, a 10% processing will be applied.<br/><br/>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.`},                    
                ],
                "headerButtons":[
                  {
                    "id":"btnClose"
                  }
                ],
                "buttons": [
                  {
                    "id":"btnNext",
                    "label":"Proceed to Cancel"
                  }
                ]
            },
            {
                "id": "confirm-cancellation",
                "title": "Confirm Cancellation",
                "onSuccessUpdateUser": true,    // Need to refresh balance on success
                "fields":[
                    {"isRequired":true, "id":"cancellation_reason", "label":"Reason for cancellation","type":FieldType.SELECT_DROPDOWN, domain:'cancellation_reason'},
                ],
                "headerButtons":[
                  {
                    "id":"btnBack"
                  }
                ],
                "buttons": [
                  {
                    "id":"btnSubmit",
                    "label":"Confirm Cancellation"
                  }
                ]
            },
            {
                "id": "confirmation",
                "title": "Cancellation Confirmed",
                "fields":[
                    {id:"lblh",type:FieldType.HTML, label:
                    `<p>Your booking has been cancelled. A credit of the full amount has been applied.</p>
                    <p>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                    },                                        
                ],
                "buttonsContainerStyle":"space-between",            
                "buttons": [
                  {
                    "id":"btnNext",
                    "label":"Request Cash Refund"
                  },
                  {
                    "id":"btnClose",
                    "label":"View Booking"
                  }
                ]
            },
            {
              "id": "refund1",
              "title": "Cash Refund",
              "onSuccessUpdateUser": true,    // Need to refresh balance on success              
              "itemType": "booking_refund", // OVERRIDE
              "fields":[
                  {id:"lblh",type:FieldType.HTML, label:
                  `<p>By clicking confirm, you will receive a full cash refund (minus a 10% transaction fee) back to
                  your card</p>
                  <p>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                  },                                        
              ],
              "headerButtons":[
                {
                  "id":"btnBack"
                }
              ],
              "buttons": [
                {
                  "id":"btnSubmit",
                  "label":"Confirm Refund"
                }
              ]
          },
          {
            "id": "refund2",
            "title": "Refund Confirmation",
            "fields":[
                {id:"lblh",type:FieldType.HTML, label:
                `<p>Your refund request has been processed. You will receive a full refund (minus a 10% transaction fee) back to the card used within 14 business days.</p>`
                },                                        
            ],
            "buttons": [
              {
                "id":"btnClose",
                "label":"View Booking"
              }
        ]
        },

        ]
    },  
  "public_meriton_end_booking": {
    "formStyle": "profile",
    "className": "editform-profile",
    "pages":[
        {
            "id": "start",
            "title": "End Your Booking",
            "fields":[
                {"id":"id.header", "label":"<p>Please select your end date</p>","type":FieldType.HTML},
                {"isRequired":true, "id":"cease_date", "label":"End Date","type":FieldType.DATE_ALL, minDate: "min_cease_date"},
                {"isRequired":true, "id":"cease_reason", "label":"Reason for cancellation","type":FieldType.SELECT_DROPDOWN, domain:'cease_reason'},
                {id:"lblh",type:FieldType.HTML, label:
                `<p style="margin-top:50px;"><b>Note:</b> A minimum notice period of 1 month is required to end a booking.</p>
                <p>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                }                
            ],
            "headerButtons":[
              {
                "id":"btnClose"
              }
            ],
            "buttons": [
              {
                "id":"btnSubmit",
                "label":"Confirm End Date"
              }
            ]
        },
        {
            "id": "confirmation",
            "title": "End Date Confirmation",
            "fields":[
                {id:"lblh",type:FieldType.HTML, label:
                `<p>Your booking {{reference}} ends on <b>{{cease_date}}</b>.</p>
                <p>If you have been issued an access card or key, please make sure to return it on or before <b>{{cease_date}}</b> or
                you may be charged a penalty.</p>`
                },                                        
            ],
            "buttons": [
              {
                "id":"btnClose",
                "label":"View Booking"
              }
            ]
        },
    ]
  },
  "public_meriton_end_booking_owner": {
    "formStyle": "profile",
    "className": "editform-profile",
    "pages":[
        {
            "id": "start",
            "title": "End Your Booking",
            "fields":[
                {"id":"id.header", "label":"<p>Please select your end date</p>","type":FieldType.HTML},
                {"isRequired":true, "id":"cease_date", "label":"End Date","type":FieldType.DATE_ALL, minDate: "min_cease_date"},
                {id:"lblh",type:FieldType.HTML, label:
                `<p style="margin-top:50px;"><b>Note:</b> A minimum notice period of 1 month is required to end a booking.</p>
                <p>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                }                
            ],
            "headerButtons":[
              {
                "id":"btnClose"
              }
            ],
            "buttons": [
              {
                "id":"btnSubmit",
                "label":"Confirm End Date"
              }
            ]
        },
        {
            "id": "confirmation",
            "title": "End Date Confirmation",
            "fields":[
                {id:"lblh",type:FieldType.HTML, label:
                `<p>The booking {{reference}} ends on <b>{{cease_date}}</b>.</p>
                `
                },                                        
            ],
            "buttons": [
              {
                "id":"btnClose",
                "label":"View Booking"
              }
            ]
        },
    ]
  },
  "public_meriton_cancel_booking": {
    "formStyle": "profile",
    "className": "editform-profile",
        "pages":[
            {
                "id": "start",
                "title": "Cancel Booking",
                "fields":[
                    {id:"lblh",type:FieldType.HTML, label:`You may cancel your booking on or before the commencement date of your rental. A credit for the full amount will automatically be applied to your account.<br/><br/>Credits can be used towards any other booking on the Share with Oscar platform. If you would like to request a refund, a 10% processing will be applied.<br/><br/>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.`},                    
                ],
                "headerButtons":[
                  {
                    "id":"btnClose"
                  }
                ],
                "buttons": [
                  {
                    "id":"btnNext",
                    "label":"Proceed to Cancel"
                  }
                ]
            },
            {
                "id": "confirm-cancellation",
                "title": "Confirm Cancellation",
                "onSuccessUpdateUser": true,    // Need to refresh balance on success
                "fields":[
                    {"isRequired":true, "id":"cancellation_reason", "label":"Reason for cancellation","type":FieldType.SELECT_DROPDOWN, domain:'cancellation_reason'},
                ],
                "headerButtons":[
                  {
                    "id":"btnBack"
                  }
                ],
                "buttons": [
                  {
                    "id":"btnSubmit",
                    "label":"Confirm Cancellation"
                  }
                ]
            },
            {
                "id": "confirmation",
                "title": "Cancellation Confirmed",
                "fields":[
                    {id:"lblh",type:FieldType.HTML, label:
                    `<p>Your booking has been cancelled. A credit of the full amount has been applied.</p>
                    <p>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                    },                                        
                ],
                "buttonsContainerStyle":"space-between",            
                "buttons": [
                  {
                    "id":"btnNext",
                    "label":"Request Cash Refund"
                  },
                  {
                    "id":"btnClose",
                    "label":"View Booking"
                  }
                ]
            },
            {
              "id": "refund1",
              "title": "Cash Refund",
              "onSuccessUpdateUser": true,    // Need to refresh balance on success              
              "itemType": "booking_refund", // OVERRIDE
              "fields":[
                  {id:"lblh",type:FieldType.HTML, label:
                  `<p>By clicking confirm, you will receive a full cash refund (minus a 10% transaction fee) back to
                  your card</p>
                  <p>View the <a href='https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/' target='_blank'>cancellation policy</a> here.</p>`
                  },                                        
              ],
              "headerButtons":[
                {
                  "id":"btnBack"
                }
              ],
              "buttons": [
                {
                  "id":"btnSubmit",
                  "label":"Confirm Refund"
                }
              ]
          },
          {
            "id": "refund2",
            "title": "Refund Confirmation",
            "fields":[
                {id:"lblh",type:FieldType.HTML, label:
                `<p>Your refund request has been processed. You will receive a full refund (minus a 10% transaction fee) back to the card used within 14 business days.</p>`
                },                                        
            ],
            "buttons": [
              {
                "id":"btnClose",
                "label":"View Booking"
              }
        ]
        },

        ]
    },
  "public_meriton_edit_profile": {
    "pages":[
        {
            "id": "start",
            "title:": "Start - Page 1",
            "fields":[
                {"id":"telephone_number", "label":"Mobile","type":FieldType.TEXT_STRING, readOnly:true},
                {"id":"email", "label":"Email","type":FieldType.TEXT_STRING, readOnly:true},
                {"id":"building", "label":"Building","type":FieldType.TEXT_STRING, readOnly:true},
                {"id":"tenant_id", "label":"Unit#","type":FieldType.TEXT_STRING, readOnly:true},
                {"id":"lease_end_date", "label":"End of lease date","type":FieldType.DATE_ALL},
            ],
            "buttonsContainerStyle":"left",            
            "buttons": [
              {
                "id":"btnSubmit",
                "label":"Save Changes"
              }
            ]

        }
    ]
  },
  "edit_booking": {
        "pages":[
            {
                "id": "start",
                "title:": "Start - Page 1",
                "fields":[
                    {"isRequired":false, "id":"access_code", "type":FieldType.TEXT_STRING, placeholder:"Enter a PIN or Pass # here"}
                ],
                "buttons": [
                  {
                    "id":"btnSubmit",
                    "label":"Save Changes"
                  }
                ]
        
            }
        ]
  },
  "edit_parkingbay": {
      "pages":[
          {
              "id": "start",
              "title": `Edit Parking Bay<span style="color:var(--heroColor2)"> - {{parent.building_name}}</span>`,
              "title_new": `Add New Parking Bay<span style="color:var(--heroColor2)"> - {{parent.building_name}}</span>`,
              //"onSuccessUrl": "/cp/building_bays/{{building.uuid}}",
              onSuccessReturnUrl: true,
              "fields":[
                  {"isRequired":false, "id":"bay_marking", "label":"Bay Number #","type":FieldType.TEXT_STRING, placeholder:"Enter bay number"},
                  {"isRequired":true, "id":"vehicle_type", label:"Max. Vehicle Size", type:FieldType.SELECT_DROPDOWN, domain:'vehicle_types', placeholder:"How big is the spot?"},
                  {"id":"id.title", "label":"Type","type":FieldType.TITLE},
                  {"isRequired":true, "id":"bay_group", "label":"Bay Group","type":FieldType.SELECT_DROPDOWN, domain:'group_types'},
                  {"id":"id.title", "label":"Pricing","type":FieldType.TITLE},
                  {id:"lblh",type:FieldType.ADVICE_BOX, label:`If this field is left blank, the <b>building default rate of {{parent.rate_monthly_default}}</b> will apply.`},                    
                  {"isRequired":true, "id":"rate_monthly", label:"Monthly Rate", type:FieldType.MONEY_ONLY_POSITIVE_DOLLARS, placeholder: " "},
                  {"id":"id.title", "label":"Availability","type":FieldType.TITLE},
                  {"isRequired":true, "id":"availability_type", "label":"Availability Type","type":FieldType.SELECT_DROPDOWN, domain:'availability_types'},
                  {"id":"id.title", "label":"Details","type":FieldType.TITLE},
                  {"isRequired":true, "id":"description", label:"Description", type:FieldType.TEXT_STRING, placeholder:"Optional. If not filled out, the building settings will apply"},
                  {"isRequired":true, "id":"instruction", label:"Access Instructions", type:FieldType.TEXT_STRING, placeholder:"Optional. If not filled out, the building settings will apply"}
              ],
              "buttons": [
                {
                  "id":"btnSubmit",
                  "label":"Save Changes"
                }
              ]
        
          }
      ]

    },
  "edit_building": {
      "pages":[
          {
              "id": "start",
              "title:": "Start - Page 1",
              "title": "Edit Building",
              //"onSuccessUrl": "/cp/wp_buildings",
              onSuccessReturnUrl: true,                
              "fields":[
                  {"isRequired":true, "id":"building_name", "label":"Building Name","type":FieldType.TEXT_STRING},
                  {"id":"id.title", "label":"Address","type":FieldType.TITLE},
                  {"id":"building_address", "label":"Address","type":FieldType.DISPLAY},

                  {"isRequired":true, "id":"access_method", "label":"Access Type","type":FieldType.SELECT_DROPDOWN, domain:'access_methods', placeholder:"Placeholder here"},
                  
                  {"id":"id.title", "label":"Pricing Rules","type":FieldType.TITLE},
                  {id:"lblh",type:FieldType.ADVICE_BOX, label:`Tenant pricing will be subject to the min/max values supplied for the building here.<br/>If a parking bay does not have a monthly rate, the <b>building default rate</b> will apply.`},                    
                  {"isRequired":true, "id":"rate_monthly_default", "label":"Rate Monthly - Default","type":FieldType.MONEY_ONLY_POSITIVE_DOLLARS},
                  {"isRequired":true, "id":"rate_monthly_min", "label":"Rate Monthly - Min","type":FieldType.MONEY_ONLY_POSITIVE_DOLLARS},
                  {"isRequired":true, "id":"rate_monthly_max", "label":"Rate Monthly - Max","type":FieldType.MONEY_ONLY_POSITIVE_DOLLARS},

                  {"id":"id.title", "label":"Building Details","type":FieldType.TITLE},
                  {"isRequired":true, "id":"rules_text", "label":"Building Rules","type":FieldType.TEXT_STRING, "placeholder":"This text will be displayed at the top of each listing"},
                  {"isRequired":true, "id":"tagline", "label":"Tagline","type":FieldType.TEXT_STRING, "placeholder":"Placeholder here"},
                  {"isRequired":true, "id":"description", "label":"Description","type":FieldType.TEXT_STRING,"placeholder":"Describe what makes this building unique"},
                  {"isRequired":true, "id":"instruction", "label":"Access Instructions (not working yet)","type":FieldType.TEXT_STRING, "placeholder":"Describe how to access this building. This will be hidden until a booking is made"}
              ],
              "buttons": [
                {
                  "id":"btnSubmit",
                  "label":"Save Changes"
                }
              ]
        
          }
      ]
  }
};

